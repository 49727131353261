<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <a-col :span="6">
        <a-form-item label="费用项目名称" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              placeholder="请输入费用项目名称"
              v-decorator="['cost_name']"
          />
        </a-form-item>
        <a-form-item label="项目类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              v-decorator="['cost_type']"
              :options="[{ label: '装车', value: 0 },{ label: '修车', value: 1 },{ label: '其他', value: 2 }]"
              placeholder="请选择项目类型"
          />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="启用状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              allowClear
              v-decorator="['status']"
              :options="[{ label: '启用', value: 0 },{ label: '停用', value: 1 }]"
              placeholder="请选择启用状态"
          />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="添加时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-range-picker
              format="YYYY-MM-DD"
              v-decorator="['create_time']"
          />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button style="margin-right: 10px" type="primary" html-type="submit" icon="search">搜索</a-button>
          <a-button @click="handleReset">重置表单</a-button>
        </a-form-item>
      </a-col>
      <a-col :span="24" style="text-align: right">
      </a-col>
    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'

export default {
  name: 'searchForm',
  components: {
    SelectRegion
  },
  data() {
    return {
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      const {searchForm: {validateFields}} = this
      validateFields((errors, values) => {
        if (errors) {
          return false
        }
        this.$emit('handleSubmit', values)
      })
    },
    handleReset() {
      this.searchForm.resetFields()
    }
  }
}
</script>
<style scoped lang="less">

</style>
